import { CIcon } from '~/icons/types'
export const ciX: CIcon = {
  width: 24,
  height: 24,
  paths: [
    {
      d:
        'm12.803 11.59 5.4 7.725h-2.216l-4.407-6.303-.647-.926-5.149-7.364h2.217l4.155 5.943z'
    },
    {
      d:
        'M21.407 0H2.593A2.593 2.593 0 0 0 0 2.593v18.814A2.593 2.593 0 0 0 2.593 24h18.814A2.593 2.593 0 0 0 24 21.407V2.593A2.593 2.593 0 0 0 21.407 0m-6.099 20.353-4.46-6.491-5.584 6.49H3.821l6.386-7.422-6.386-9.295h4.871l4.223 6.147 5.288-6.147h1.443l-6.09 7.079 6.623 9.638z'
    }
  ],
  name: 'x',
  type: 'brand'
}
