import darkLogo from '~/assets/img/logos/logo-dark.png'
import lightLogo from '~/assets/img/logos/logo-light.png'
import logo from '~/assets/img/logos/logo.png'
import { containerScoped } from '~/decorators/dependency-container'
import { colorIsDark } from '~/utils/color'

const logos: Record<string, string> = {
  logo,
  darkLogo,
  lightLogo
}

@containerScoped()
export default class LogoService {
  /**
   * Logo dimensions are 122 by 38 px.
   * @param backgroundColor
   * @param colored
   */
  getLogo(backgroundColor: string = '#000000', colored = false): string {
    const backgroundIsDark = colorIsDark(backgroundColor)
    if (backgroundIsDark) {
      if (colored) {
        return logos.logo
      }
      return logos.lightLogo
    }
    return logos.darkLogo
    // TODO: Optimize so that logos load asynchronously and are cached if they already have been loaded.
  }
}
