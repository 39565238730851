





















































import { ValueOf } from 'type-fest'
import { mapGetters } from 'vuex'
import {
  Alignment,
  TextOptions,
  CssBackgroundImage
} from '~/models/dealer/site/types'
import { mapDeps } from '~/plugins/dependency-container/utils'
import DealerSiteLayoutService from '~/services/dealers/site/DealerSiteLayoutService'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import CLineBreaker from '~/components/shared/configurable/CLineBreaker.vue'
import NavToggleButton from './nav/NavToggleButton.vue'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {
    NavToggleButton,
    CLineBreaker
  },
  computed: {
    ...mapDeps({ dealerSiteService: DealerSiteService }),
    ...mapGetters(DEALER_SITE_NS, {
      getOption: 'getTemplateOption',
      indexPage: 'indexPage'
    }),
    title(): TextOptions {
      return {
        text: this.getOption('header_text'),
        textColor: this.getOption('header_text_color'),
        visible: this.getOption('header_text_visible')
      }
    },
    description(): TextOptions {
      return {
        text: this.getOption('header_subtext'),
        textColor: this.getOption('header_text_color'),
        visible: this.getOption('header_subtext_visible')
      }
    },
    backgroundImage(): { url?: string; size: string } {
      return {
        url: this.getOption('header_photo'),
        size: this.getOption('header_photo_stretch')
      }
    },
    backgroundColor(): string | undefined {
      return this.getOption('header_background_color')
    },
    height(): string | undefined {
      return this.getOption('header_height')
    },
    textAlignment(): Alignment | undefined {
      return this.getOption('header_align')
    },
    rightImageUrl(): string | undefined {
      return this.getOption('header_right_photo')
    },
    leftImage(): { url?: string; alignment?: Alignment } {
      return {
        url: this.getOption('header_left_photo'),
        alignment: this.getOption('header_left_photo_position')
      }
    },
    backgroundImageCssRules(): Record<string, string> | {} {
      const dealerSiteLayoutService = this.$dep(DealerSiteLayoutService)
      const images: CssBackgroundImage[] = []
      dealerSiteLayoutService.imageUrlShouldBeLoaded(this.leftImage.url) &&
        images.push({
          url: this.leftImage.url!,
          size: 'auto 90%', // TODO: since this is 90% for backwards compatibility, change this to 100% to give customizer more flexibility.
          repeat: 'no-repeat',
          position: this.leftImage.alignment || 'left'
        })
      dealerSiteLayoutService.imageUrlShouldBeLoaded(this.rightImageUrl) &&
        images.push({
          url: this.rightImageUrl!,
          size: 'auto 90%', // TODO: since this is 90% for backwards compatibility, change this to 100% to give customizer more flexibility.
          repeat: 'no-repeat',
          position: 'right'
        })
      dealerSiteLayoutService.imageUrlShouldBeLoaded(
        this.backgroundImage.url
      ) &&
        images.push({
          url: this.backgroundImage.url!,
          size: this.backgroundImage.size,
          repeat: 'no-repeat',
          position: 'center'
        })

      const createRule = (
        getter: (
          image: CssBackgroundImage
        ) => ValueOf<CssBackgroundImage> | string
      ): string => images.map(getter).join(', ')
      const r = createRule

      return images.length
        ? {
            backgroundImage: r(({ url }) => `url(${url})`),
            backgroundSize: r(({ size }) => size),
            backgroundRepeat: r(({ repeat }) => repeat),
            backgroundPosition: r(({ position }) => position)
          }
        : {}
    }
  }
})
