



















import { mapGetters } from 'vuex'
import SearchShimmer from '~/components/car/classifieds/search/shimmers/SearchShimmer.vue'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {
    SearchShimmer
  },
  computed: {
    ...mapGetters(DEALER_SITE_NS, ['getTemplateOption', 'loadingPage']),
    loadingSearchPage(): boolean {
      return Boolean(
        this.loadingPage &&
          ['search', 'search_custom_page'].includes(this.loadingPage.type)
      )
    }
  }
})
