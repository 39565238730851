











import { mapGetters } from 'vuex'
import DealerSiteLayoutService from '~/services/dealers/site/DealerSiteLayoutService'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    size: {
      type: String,
      default: '2rem'
    }
  },
  computed: {
    ...mapGetters(DEALER_SITE_NS, ['getTemplateOption']),
    pageTextColorRgb(): string {
      return this.$dep(DealerSiteLayoutService).convertColorToRgb(
        this.getTemplateOption('page_text_color')
      )
    }
  }
})
