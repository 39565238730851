




















import { Context } from '@nuxt/types'
import { mapGetters, mapState, mapActions } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { getDealerSiteRouteName } from '~/utils/router'
import PageContainer from '~/components/car/dealers/site/page/PageContainer.vue'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import { PAGE_NS } from '~/store/modules/shared/page/state'
import { getNamespacedStore } from '~/utils/store'
import Header from '~/components/car/dealers/site/Header.vue'
import ExpandableLineNav from '~/components/car/dealers/site/nav/ExpandableLineNav.vue'
import ExpandableOffCanvasNav from '~/components/car/dealers/site/nav/OffCanvasNav/ExpandableOffCanvasNav.vue'
import Footer from '~/components/car/dealers/site/footer/Footer.vue'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import DealerSiteAnalytics from '~/services/telemetry/DealerSiteAnalytics'
import { mapDeps } from '~/plugins/dependency-container/utils'
import LanguageSwitcher from '~/services/LanguageSwitcher'
import AppSnackbar from '~/components/car/AppSnackbar.vue'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {
    AppSnackbar,
    Header,
    ExpandableLineNav,
    ExpandableOffCanvasNav,
    PageContainer,
    Footer
  },
  async asyncData(ctx: Context) {
    if (process.server) {
      const { route, $dep } = ctx

      if (route.name !== getDealerSiteRouteName('page')) {
        const dsStore = getNamespacedStore(ctx.store, DEALER_SITE_NS)
        await dsStore.dispatch('loadSite', ctx)

        const dealerSiteService = $dep(DealerSiteService)
        if (route.name === getDealerSiteRouteName()) {
          ctx.redirect(
            dealerSiteService.resolvePageRoute(dsStore.getters('indexPage'))
              .href
          )
        }
      }
    }
  },
  data() {
    return {
      availableLocales: this.$dep(LanguageSwitcher).getAvailableLocales()
    }
  },
  computed: {
    ...mapGetters(DEALER_SITE_NS, [
      'getTemplateOption',
      'indexPage',
      'activePage'
    ]),
    ...mapState(DEALER_SITE_NS, ['title']),
    ...mapState(CLASSIFIED_SEARCH_NS, {
      currentRoute: state => state.currentRoute
    }),
    ...mapDeps({
      languageSwitcherService: LanguageSwitcher
    }),
    bodyBackgroundColor(): string {
      return this.getTemplateOption('footer_background_color')
    },
    classes(): Record<string, boolean> {
      if (!this.activePage) {
        return {}
      }

      const className = `${this.activePage.type}-active`
      return { [className]: true }
    },
    ...mapState(PAGE_NS, ['error'])
  },
  watch: {
    currentRoute(val) {
      this.availableLocales = this.languageSwitcherService.getAvailableLocales(
        val
      )
    },
    $route() {
      this.availableLocales = this.languageSwitcherService.getAvailableLocales()
    }
  },
  mounted() {
    const { createTemplateConfSettingFunction } = this

    if (!('setDealerSiteTemplateConf' in window)) {
      createTemplateConfSettingFunction()
    }

    const dealerSiteAnalyticsService = this.$dep(DealerSiteAnalytics)
    dealerSiteAnalyticsService.createConfigs()
  },
  methods: {
    ...mapActions(DEALER_SITE_NS, [
      'loadSite',
      'createTemplateConfSettingFunction'
    ])
  },
  head() {
    const bodyAttrs = this.error
      ? {}
      : {
          style: `background-color: ${this.bodyBackgroundColor};`
        }

    return {
      title: this.title, // Title used as fallback for pages with undefined titles.
      meta: [
        {
          name: 'robots',
          content: 'nosnippet'
        }
      ],
      bodyAttrs
    }
  }
})
