import { inject } from 'tsyringe'
import { Store } from 'vuex'
import { containerScoped } from '~/decorators/dependency-container'
import { NamespacedStore } from '~/models/store/types'
import AnalyticsService from '~/services/AnalyticsService'
import {
  DealerSiteAnalyticsState,
  DEALER_SITE_ANALYTICS_NS
} from '~/store/modules/shared/dealers/site/analytics/state'
import { StoreWithRootState } from '~/store/types'
import { getNamespacedStore } from '~/utils/store'

@containerScoped()
export default class DealerSiteAnalytics {
  private dealerSiteAnalyticsStore: NamespacedStore<DealerSiteAnalyticsState>
  constructor(
    @inject(Store) store: StoreWithRootState,
    @inject(AnalyticsService) private analytics: AnalyticsService
  ) {
    this.dealerSiteAnalyticsStore = getNamespacedStore(
      store,
      DEALER_SITE_ANALYTICS_NS
    )
  }

  createConfigs() {
    const { code, codeV4 } = this.dealerSiteAnalyticsStore.state.google
    code && this.analytics.createGtagConfig(code)
    codeV4 && this.analytics.createGtagConfig(codeV4)
  }
}
