

























































import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { mapState, mapGetters, mapActions } from 'vuex'
import DefaultNavItemContent from '~/components/car/dealers/site/nav/NavItemContent/DefaultNavItemContent.vue'
import SearchPageNavItemContent from '~/components/car/dealers/site/nav/NavItemContent/SearchPageNavItemContent.vue'
import { mapDeps } from '~/plugins/dependency-container/utils'
import DealerSiteComponentService from '~/services/dealers/site/DealerSiteComponentService'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { defineComponent } from '@nuxtjs/composition-api'

const defaultExpandedNavHeight = 'auto'

export default defineComponent({
  components: {
    DefaultNavItemContent,
    SearchPageNavItemContent
  },
  data(): {
    expandedNavHeight: string
    collapsedNavHeight: string
    expanded: boolean
  } {
    return {
      expandedNavHeight: defaultExpandedNavHeight,
      collapsedNavHeight: undefined,
      expanded: true
    }
  },
  computed: {
    ...mapDeps({
      dealerSiteService: DealerSiteService,
      dealerSiteComponentService: DealerSiteComponentService
    }),
    ...mapState(DEALER_SITE_NS, ['pageSnippets', 'websiteId']),
    ...mapGetters(DEALER_SITE_NS, [
      'getTemplateOption',
      'activePageId',
      'navPages'
    ]),
    textColor(): string {
      return this.getTemplateOption('menu_link_color')
    },
    backgroundColor(): string {
      return this.getTemplateOption('menu_background_color')
    },
    expandable(): boolean {
      return Boolean(
        this.collapsedNavHeight &&
          this.expandedNavHeight !== defaultExpandedNavHeight &&
          this.collapsedNavHeight !== this.expandedNavHeight
      )
    },
    icons(): Record<string, IconDefinition> {
      return { chevron: faChevronDown }
    }
  },
  mounted() {
    this.updateExpandedNavHeight()

    window.addEventListener('resize', this.updateExpandedNavHeight)
    window.addEventListener('click', this.collapseNavOnClickOut)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateExpandedNavHeight)
    window.removeEventListener('click', this.collapseNavOnClickOut)
  },
  methods: {
    ...mapActions(DEALER_SITE_NS, {
      dispatchNavigationToPage: 'navigateToPage'
    }),
    navigateToPage(e: Event, id: string) {
      e.preventDefault()
      if (this.activePageId !== id) {
        this.dispatchNavigationToPage(id)
      }
    },
    collapseNavOnClickOut(e: Event) {
      if (!this.expanded) {
        return
      }

      const { siteNavCol } = this.$refs
      if (!siteNavCol?.$el?.contains(e.target)) {
        this.expanded = false
      }
    },
    updateExpandedNavHeight() {
      const { siteNav } = this.$refs
      if (!siteNav || !siteNav.children || !siteNav.children.length) {
        this.expandedNavHeight = defaultExpandedNavHeight
      }
      const { children: items } = siteNav
      const unit: number = items[0].clientHeight
      this.collapsedNavHeight = `${unit}px`

      let height: number = unit
      let prevItem: HTMLElement = items[0] as HTMLElement
      for (const item of items) {
        if (item.offsetTop !== prevItem.offsetTop) {
          height = height + unit
        }
        prevItem = item
      }

      if (height > unit) {
        // Add some bottom margin so that user does not accidentally collapse the menu while trying to target an element
        // close to the bottom.
        height = height + Math.ceil(unit / 2)
      }

      this.expandedNavHeight = `${height}px`
    }
  }
})
