








import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { mapGetters, mapActions } from 'vuex'
import { DEALER_SITE_LAYOUT_NS } from '~/store/modules/shared/dealers/site/layout/state'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  computed: {
    ...mapGetters(DEALER_SITE_NS, ['getTemplateOption']),
    icons: (): Record<string, IconDefinition> => ({
      faBars
    })
  },
  methods: {
    ...mapActions(DEALER_SITE_LAYOUT_NS, ['toggleNav'])
  }
})
