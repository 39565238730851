import { render, staticRenderFns } from "./DirectionsButton.vue?vue&type=template&id=629631aa&scoped=true&"
import script from "./DirectionsButton.vue?vue&type=script&lang=ts&"
export * from "./DirectionsButton.vue?vue&type=script&lang=ts&"
import style0 from "./DirectionsButton.vue?vue&type=style&index=0&id=629631aa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "629631aa",
  null
  
)

export default component.exports