

























import { faDirections } from '@fortawesome/free-solid-svg-icons'
import CDynamicIcon from '~/components/shared/configurable/icon/CDynamicIcon.vue'
import { defineComponent } from '@nuxtjs/composition-api'
import { useI18n } from '~/compositions/i18n'

export default defineComponent({
  components: { CDynamicIcon },
  props: {
    link: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'md'
    },
    shadow: {
      type: [String, null],
      default: 'shadow-sm'
    },
    label: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: 'blue-dark'
    },
    linkWithIcon: {
      type: Boolean,
      default: false
    },
    icon: {
      type: null,
      default: () => faDirections
    }
  },
  setup(props) {
    const { t } = useI18n()

    const buttonLabel = props.label || t('directions')

    return {
      faDirections,
      buttonLabel
    }
  }
})
