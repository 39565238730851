import { render, staticRenderFns } from "./SearchShimmer.vue?vue&type=template&id=f49a925e&scoped=true&"
import script from "./SearchShimmer.vue?vue&type=script&lang=ts&"
export * from "./SearchShimmer.vue?vue&type=script&lang=ts&"
import style0 from "./SearchShimmer.vue?vue&type=style&index=0&id=f49a925e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f49a925e",
  null
  
)

export default component.exports