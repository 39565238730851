

















































import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { mapState, mapGetters, mapActions } from 'vuex'
import { DEALER_SITE_LAYOUT_NS } from '~/store/modules/shared/dealers/site/layout/state'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import NavItem from './NavItem.vue'
import { defineComponent } from '@nuxtjs/composition-api'
import LanguagePicker from '~/components/car/dealers/site/LanguagePicker.vue'

export default defineComponent({
  components: {
    NavItem,
    LanguagePicker
  },
  props: {
    availableLocales: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState(DEALER_SITE_LAYOUT_NS, ['navOpen']),
    ...mapGetters(DEALER_SITE_NS, [
      'getTemplateOption',
      'pageIsActive',
      'indexPage',
      'contactPage',
      'searchPagesOfNav',
      'customPagesOfNav'
    ]),
    icons: () => ({
      faTimes
    })
  },
  methods: {
    ...mapActions(DEALER_SITE_LAYOUT_NS, ['closeNav'])
  }
})
