














import { mapGetters } from 'vuex'
import { PageSnippet } from '~/models/dealer/site/page-snippet/types'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import { formatNumber } from '~/utils/number'
import DealerSiteLayoutService from '~/services/dealers/site/DealerSiteLayoutService'
import CBadge from '~/components/shared/configurable/badge/CBadge.vue'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {
    CBadge
  },
  props: {
    page: {
      type: Object as PropType<PageSnippet>,
      required: true
    }
  },
  computed: {
    ...mapGetters(DEALER_SITE_NS, ['getTemplateOption']),
    backgroundRgb(): string {
      const menuLinkColor: string = this.getTemplateOption('menu_link_color')
      const dealerSiteLayoutService = this.$dep(DealerSiteLayoutService)
      return dealerSiteLayoutService.convertColorToRgb(menuLinkColor)
    },
    count(): string {
      const count = (this.page.classifieds && this.page.classifieds.count) || 0
      return formatNumber(count)
    }
  }
})
