





















import { mapGetters } from 'vuex'
import LanguageSwitcher from '~/services/LanguageSwitcher'
import { I18N_NS } from '~/store/modules/shared/i18n/state'

import { AvailableLocale, I18nLocale } from '~/models/shared/types'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    availableLocales: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(I18N_NS, ['localeIsActive']),
    localeCodeToFlag: () =>
      new Map([
        [I18nLocale.EN, require('~/assets/img/flags/4x3/en.svg')],
        [I18nLocale.EL, require('~/assets/img/flags/4x3/el.svg')]
      ])
  },
  methods: {
    async handleButtonClick(e: Event, availableLocale: AvailableLocale) {
      e.preventDefault()
      if (this.localeIsActive(availableLocale.locale)) {
        return
      }

      try {
        await this.$dep(LanguageSwitcher).switchTo(availableLocale)
      } catch (error) {
        this.$logger.captureError(error)
      }
    }
  }
})
