



































import { IconPack } from '@fortawesome/fontawesome-common-types'
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import { mapGetters, mapState } from 'vuex'
import DirectionsButton from '../../../maps/DirectionsButton.vue'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {
    DirectionsButton
  },
  computed: {
    ...mapState(DEALER_SITE_NS, ['outlets']),
    ...mapGetters(DEALER_SITE_NS, {
      outlet: 'firstOutlet',
      getTemplateOption: 'getTemplateOption'
    }),
    icons(): IconPack {
      return {
        faPhone,
        faMapMarkerAlt,
        faEnvelope
      }
    },
    textColor(): string {
      return this.getTemplateOption('footer_text_color')
    }
  }
})
