












import { toBase64 } from '~/utils/string'
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { Component } from 'vue'

export default defineComponent({
  props: {
    container: {
      type: [Object, String],
      default: 'p'
    },
    lineComponent: {
      type: [Object, String] as PropType<
        Component | keyof HTMLElementTagNameMap
      >,
      default: 'span'
    },
    lineComponentClass: {
      type: String,
      default: 'tw-block'
    },
    text: {
      type: String,
      required: true
    }
  },
  computed: {
    lines(): string[] {
      if (!this.text) {
        return []
      }

      return this.text.split('\n')
    }
  },
  methods: {
    toBase64
  }
})
