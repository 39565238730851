






import { PageSnippet } from '~/models/dealer/site/page-snippet/types'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    page: {
      type: Object as PropType<PageSnippet>,
      required: true
    }
  }
})
