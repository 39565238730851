









import CDirectionsButton from '~/components/shared/configurable/map/CDirectionsButton.vue'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {
    CDirectionsButton
  },
  props: {
    lat: {
      type: Number,
      required: true
    },
    lon: {
      type: Number,
      required: true
    },
    size: {
      type: String,
      default: 'md'
    },
    label: {
      type: String,
      default: undefined
    }
  }
})
