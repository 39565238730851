import { singleton } from '~/decorators/dependency-container'
import { PageType } from '~/models/dealer/site/types'

@singleton()
export default class DealerSiteComponentService {
  getPageComponentName(type: PageType): string {
    switch (type) {
      case 'custom_page':
        return 'custom-page'
      case 'search':
      case 'search_custom_page':
        return 'search'
      case 'contact':
        return 'contact'
      case 'classified':
        return 'classified'
      default:
        return 'custom-page'
    }
  }

  getNavItemComponentName(type: PageType): string {
    switch (type) {
      case 'search':
      case 'search_custom_page':
        return 'SearchPageNavItemContent'
      default:
        return 'DefaultNavItemContent'
    }
  }
}
